<template>
  <v-container fluid style="margin-bottom: 100px">
    <v-row class="mt-2" justify="center">
      <v-col cols="12" md="11">
        <v-row>
          <v-col class="d-flex flex-column align-start">
            <h1 class="secondary--text">
              {{ `${ isEdit ? 'Editar' : isCopy ? 'Cópiar' : ''} Configurações de Colunas do Layout` }}
            </h1>
          </v-col>
          <v-col cols="12" md="5" class="py-0">
            <v-stepper style="background-color: var(--v-background-base); box-shadow: none !important;" class="stepper">
              <v-stepper-header>
                <v-stepper-step step="1">Layout</v-stepper-step>
                <v-divider></v-divider>
                <v-stepper-step step="2">
                  Transformações <br>e Mapa de Campo
                </v-stepper-step>
              </v-stepper-header>
            </v-stepper>
          </v-col>
        </v-row>
      </v-col>
    </v-row>

    <v-row justify="center" class="mt-10" ref="top">
      <v-col cols="12" md="11" class="px-0">
        <v-form
          ref="formValidation"
          v-if="Object.keys(layoutData).length > 0">
          <HeaderConfigurations
            @headerConfigurationComponent="headerConfigurationComponent"
            @setLayoutInfoInLayoutType="setLayoutInfoInLayoutType"
            @isEditControll="isEditControll"
            @isCopyControll="isCopyControll"
            @disabledSaveButtonControll="disabledSaveButtonControll"
          />
        </v-form>
      </v-col>
    </v-row>

    <v-form ref="formValidationColumns">
      <v-row justify="center">
        <v-col cols="12" md="11" class="px-0">
          <LayoutColumnComponent :propsLayout="layout" :propsIsVisualization="isVisualization" @updateLayout="updateLayout"/>
        </v-col>
      </v-row>
    </v-form>

    <v-snackbar
      v-model="snackbar.show"
      timeout="10000"
      top
      elevation="24"
      :color="snackbar.type"
    >
      <div class="text-center">{{ snackbar.text }}</div>
      <template v-slot:action="{ attrs }">
        <v-icon
          text
          dark
          v-bind="attrs"
          @click="snackbar.show = false"
        >
          close
        </v-icon>
      </template>
    </v-snackbar>

    <Footer
      style="z-index: 2;"
      @voltar="onClickBack"
      @cancelar="onClickCancel"
      @salvar="onClickSave"
      :disabledSave="isDisabledSave"
      saveButtonLabel="Avançar"
    />
  </v-container>
</template>

<script>
import Footer from '@/components/Footer/Footer.vue';
import LayoutColumnComponent from '@/components/Layout/Columns/LayoutColumnComponent.vue';
import HeaderConfigurations from '@/components/Layout/ImportSettingsHeaders/headerConfigurations.vue';
import { mapGetters, mapMutations } from 'vuex';

export default ({
  components: {
    Footer,
    LayoutColumnComponent,
    HeaderConfigurations,
  },

  data: () => ({
    isVisualization: false,
    isEdit: false,
    isCopy: false,
    isDisabledSave: true,
    layoutType: {},
    layout: {},
    snackbar: {
      show: false,
      type: '',
      text: '',
    },
  }),

  async mounted() {
    window.addEventListener('beforeunload', this.handlePageRefresh);
    this.setSessionStoreData();
    this.scrollTo();
    await this.loadSessionStorage();
  },

  beforeDestroy() {
    window.removeEventListener('beforeunload', this.handlePageRefresh);
  },

  methods: {
    ...mapMutations({
      setLayoutEntityType: 'layout-import/setLayoutEntityType',
      setLayoutHeader: 'layout-import/setLayoutHeader',
      setLayoutType: 'layout-import/setLayoutType',
      setLayout: 'layout-import/setLayout',
    }),
    handlePageRefresh() {
      sessionStorage.setItem('layout', JSON.stringify(this.layoutData))
      sessionStorage.setItem('layoutType', JSON.stringify(this.layoutTypeData))
      sessionStorage.setItem('layoutEntityType', this.layoutEntityType)
    },
    setSessionStoreData() {
      if (sessionStorage.getItem('layout')) {
        this.setLayout(JSON.parse(sessionStorage.getItem('layout')));
        sessionStorage.removeItem('layout')
      }
      if (sessionStorage.getItem('layoutType')) {
        this.setLayoutType(JSON.parse(sessionStorage.getItem('layoutType')));
        sessionStorage.removeItem('layoutType')
      }
      if (sessionStorage.getItem('layoutEntityType')) {
        this.setLayoutEntityType(sessionStorage.getItem('layoutEntityType'));
        sessionStorage.removeItem('layoutEntityType')
      }
    },
    async loadSessionStorage() {
      // this.layoutType = JSON.parse(sessionStorage.getItem('layoutType'));
      // this.layout = JSON.parse(sessionStorage.getItem('layout'));
      this.layoutType = JSON.parse(JSON.stringify(this.layoutTypeData));
      this.layout = JSON.parse(JSON.stringify(this.layoutData));
    },
    onClickBack() {
      this.setLayoutEntityType('');
      this.redirectRouter('back');
    },
    onClickCancel() {
      this.redirectRouter('cancel');
    },
    onClickSave() {
      if (this.$refs.formValidation.validate()) {
        if (this.$refs.formValidationColumns.validate()) {
          sessionStorage.setItem('layoutType', JSON.stringify(this.layoutType));
          sessionStorage.setItem('layout', JSON.stringify(this.layoutData));
          this.setLayoutType(this.layoutType)
          this.setLayout(this.layoutData)
          this.redirectRouter('save');
        } else {
          this.customizeSnackbarMessage('error', 'Verifique os dados das colunas do layout');
        }
      } else {
        this.customizeSnackbarMessage('error', 'Verifique os campos obrigatórios de configurações do layout');
      }
    },
    redirectRouter(type) {
      if (type === 'save') {
        if (this.isEdit) {
          this.$router.push(this.$route.query.financialGroupId ? { name: 'LayoutImportTransformationEditConfigs', query: { financialGroupId: this.layout.financialGroupId } } : { name: 'LayoutImportTransformationEditConfigs' });
        } else if (this.isCopy) {
          this.$router.push(this.$route.query.financialGroupId ? { name: 'LayoutImportTransformationCopyConfigs', query: { financialGroupId: this.layout.financialGroupId } } : { name: 'LayoutImportTransformationCopyConfigs' });
        } else {
          this.$router.push(this.$route.query.financialGroupId ? { name: 'LayoutImportTransformationConfigs', query: { financialGroupId: this.layout.financialGroupId } } : { name: 'LayoutImportTransformationConfigs' });
        }
      } else if (type === 'back') {
        if (this.isEdit || this.isCopy) {
          this.$router.push(this.$route.query.financialGroupId ? { name: 'LayoutImports', query: { financialGroupId: this.layout.financialGroupId } } : { name: 'LayoutImports' });
        } else {
          this.$router.push(this.$route.query.financialGroupId ? { name: 'LayoutImportConfigs', query: { financialGroupId: this.layout.financialGroupId } } : { name: 'LayoutImportConfigs' });
        }
      } else if (type === 'cancel') {
        this.$router.push(this.$route.query.financialGroupId ? { name: 'LayoutImports', query: { financialGroupId: this.layout.financialGroupId } } : { name: 'LayoutImports' });
      }
    },
    headerConfigurationComponent(data) {
      this.setLayoutHeader(data);
    },
    customizeSnackbarMessage(type, text) {
      this.snackbar.show = true;
      this.snackbar.type = type;
      this.snackbar.text = text;
    },
    scrollTo() {
      this.$refs.top.scrollIntoView({ behavior: 'smooth' });
    },
    setLayoutInfoInLayoutType(data) {
      this.layoutType = data;
      this.setLayoutType(data);
    },
    isEditControll(data) {
      this.isEdit = data;
    },
    isCopyControll(data) {
      this.isCopy = data;
    },
    disabledSaveButtonControll(data) {
      this.isDisabledSave = data;
    },
    updateLayout(layout) {
      this.layout = layout;
      this.setLayout(layout);
    },
  },

  computed: {
    ...mapGetters({
      layoutEntityType: 'layout-import/layoutEntityType',
      layoutTypeData: 'layout-import/layoutType',
      layoutData: 'layout-import/layout',
    }),
  }
});
</script>
